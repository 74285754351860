<template>
  <product title="米动手表 青春版"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="399"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/SpSjKh"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'bip',
  components: {
    Product
  },
  data () {
    return {
      slogan: '续航45天 | GPS+GLONASS双模定位 | 支付宝离线支付<br>运动心率 | 32克无感佩戴',
      colors: [
        '砂岩灰',
        '卡其绿',
        '火焰橙',
        '曜石黑'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/bip/swiper/5.png',
          CDN_URL + '/images/product/bip/swiper/6.png',
          CDN_URL + '/images/product/bip/swiper/7.png',
          CDN_URL + '/images/product/bip/swiper/8.png'
        ],
        [
          CDN_URL + '/images/product/bip/swiper/13.png',
          CDN_URL + '/images/product/bip/swiper/14.png',
          CDN_URL + '/images/product/bip/swiper/15.png',
          CDN_URL + '/images/product/bip/swiper/16.png'
        ],
        [
          CDN_URL + '/images/product/bip/swiper/1.png',
          CDN_URL + '/images/product/bip/swiper/2.png',
          CDN_URL + '/images/product/bip/swiper/3.png',
          CDN_URL + '/images/product/bip/swiper/4.png'
        ],
        [
          CDN_URL + '/images/product/bip/swiper/9.png',
          CDN_URL + '/images/product/bip/swiper/10.png',
          CDN_URL + '/images/product/bip/swiper/11.png',
          CDN_URL + '/images/product/bip/swiper/12.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/bip/detail/01w.jpg',
        CDN_URL + '/images/product/bip/detail/02.jpg',
        CDN_URL + '/images/product/bip/detail/03.jpg',
        CDN_URL + '/images/product/bip/detail/04.jpg',
        CDN_URL + '/images/product/bip/detail/05.jpg',
        CDN_URL + '/images/product/bip/detail/06.jpg',
        CDN_URL + '/images/product/bip/detail/07.jpg',
        // CDN_URL + '/images/product/bip/detail/08.jpg',
        CDN_URL + '/images/common/zepp-white.jpg',
        CDN_URL + '/images/product/bip/detail/09.jpg',
        CDN_URL + '/images/product/bip/detail/10.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/bip/detail/11_1.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
